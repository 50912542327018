import { Config } from '#app/config'
import node, {
  type accountUsagePageQuery,
} from '#gen/relay/accountUsagePageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute, notFound } from '@tanstack/react-router'
import { differenceInMinutes, formatISO } from 'date-fns'
import { parseTimeRange } from 'grafbase-components/inputs/time/time-range-parse.ts'
import { object, optional, parse, string } from 'valibot'

const UsagePageSearchParamsSchema = object({
  graph: optional(string()),

  range: optional(string()),
  from: optional(string()),
  to: optional(string()),
})

export const Route = createFileRoute('/_dashboard/$account/settings/usage')({
  staticData: { title: 'Usage' },
  validateSearch(search) {
    return parse(UsagePageSearchParamsSchema, search)
  },
  loaderDeps({ search }) {
    return {
      graph: search.graph,

      range: search.range ?? '7d',
      from: search.from,
      to: search.to,
    }
  },
  async loader({ context, deps, params }) {
    if (Config.IS_ENTERPRISE_BUILD) throw notFound()

    const { from, to } = parseTimeRange(deps.range, deps.from, deps.to)

    const diffInMinutes = differenceInMinutes(to, from)
    const diffInHours = diffInMinutes / 60

    let aggregationStep: string

    if (diffInHours > 24) aggregationStep = 'PT24H'
    else aggregationStep = 'PT1H'

    return context.preload<accountUsagePageQuery>(node, {
      filters: {
        from: formatISO(from),
        to: formatISO(to),
        graphSlug: deps.graph,
        aggregationStep,
      },
      account: params.account,
    })
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/usage/account-usage-page'),
    'AccountUsagePage',
  ),
})
