import node, {
  type teamsTeamTeamsPageQuery,
} from '#gen/relay/teamsTeamTeamsPageQuery.graphql'
import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/settings/teams/$team/_team/teams',
)({
  staticData: { title: 'Team Subteams' },
  loader({ context, params }) {
    return context.preload<teamsTeamTeamsPageQuery>(node, params)
  },
  component: lazyRouteComponent(
    () => import('#app/pages/account/teams/teams-page/teams-team-teams-page'),
    'TeamsTeamTeamsPage',
  ),
})
