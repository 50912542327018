import node, {
  type changelogVersionPageQuery,
} from '#gen/relay/changelogVersionPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/changelog/$version',
)({
  staticData: { title: '[graph] - Changelog entry' },
  loader({ context, params }) {
    return context.preload<changelogVersionPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/changelog/changelog-version-page'),
    'ChangelogVersionPage',
  ),
})
