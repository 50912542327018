import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/schema',
)({
  staticData: { title: '' },
  staleTime: 30_000,
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/schema/schema-layout'),
    'SchemaLayout',
  ),
})
