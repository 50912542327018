import node, {
  type deploymentDetailsOverviewPageQuery,
} from '#gen/relay/deploymentDetailsOverviewPageQuery.graphql'
import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/deployments/$id/_deployment/',
)({
  staticData: { title: '[graph] - Deployment' },
  loader({ context, params }) {
    return context.preload<deploymentDetailsOverviewPageQuery>(node, params)
  },
  component: lazyRouteComponent(
    () =>
      import('#app/pages/branch/deployments/deployment-details-overview-page'),
    'DeploymentDetailsOverviewPage',
  ),
})
