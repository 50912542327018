import { Config } from '#app/config'

export const isLocalAddressPattern =
  /^http:\/\/(?:localhost|127.0.0.1)(?:$|(?::[0-9]*|)(?:$|\/.*))$/

/**
 * Parses and validates URL's with the purpose of sending them on to a 3'd
 * party auth provider.
 * * Appends current origin to relative URL's
 * * Allows whitelisted origins that we control, as well as redirects to CLI
 *   on localhost.
 * * Falls back to current origin for non-whitelisted URLs
 *
 * @returns A fully qualified URL
 */
export function parseExternalAuthRedirectUrl(redirectUrl: string | undefined) {
  if (redirectUrl) {
    const isRelativePath = redirectUrl.startsWith('/')
    if (isRelativePath) return location.origin + redirectUrl

    const isFullInternalUrl = redirectUrl.startsWith(location.origin)
    const isCLI = isLocalAddressPattern.test(redirectUrl)

    const isGrafbaseWebsiteUrl =
      redirectUrl.startsWith(Config.WEBSITE_URL) ||
      redirectUrl.startsWith('https://grafbase.com') ||
      redirectUrl.startsWith('https://grafbase.dev') ||
      redirectUrl.startsWith('http://localhost:3000') ||
      redirectUrl.includes('.grafbase-vercel.dev')

    if (isCLI || isGrafbaseWebsiteUrl || isFullInternalUrl) return redirectUrl
  }

  return location.origin
}
