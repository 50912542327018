import Cookies from 'universal-cookie'

const STORAGE_KEY = 'last_used_account'
const cookies = new Cookies()

let isFirstLoad = true

export function getLastUsedAccount() {
  if (!isFirstLoad) return null
  isFirstLoad = false

  return cookies.get<string>(STORAGE_KEY)
}

export function setLastUsedAccount(account: string) {
  isFirstLoad = false
  cookies.set(STORAGE_KEY, account, {
    path: '/',
    // Expires in one year
    expires: new Date(Date.now() + 1_000 * 60 * 60 * 24 * 365),
  })
}

export function clearLastUsedAccount() {
  isFirstLoad = false
  cookies.remove(STORAGE_KEY, { path: '/' })
}
