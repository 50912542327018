function setRef<TInstance>(ref: React.Ref<TInstance>, instance: TInstance) {
  if (ref instanceof Function) {
    ref(instance)
  } else if (ref != null) {
    ;(ref as React.MutableRefObject<TInstance>).current = instance
  }
}

export function combinedRefs<TInstance>(refs: React.Ref<TInstance>[]) {
  return (instance: TInstance | null) =>
    refs.forEach(ref => setRef(ref, instance))
}
