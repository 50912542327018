import node, {
  type deploymentsPageQuery,
} from '#gen/relay/deploymentsPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

const PAGE_SIZE = 30

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/deployments/',
)({
  staticData: { title: '[graph] - Deployments' },
  loader({ context, params }) {
    return context.preload<deploymentsPageQuery>(node, {
      ...params,
      last: PAGE_SIZE,
      before: null,
    })
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/deployments/deployments-page'),
    'DeploymentsPage',
  ),
})
