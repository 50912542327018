/**
 * @generated SignedSource<<8f6c53a22514a421cc2fedf7424ebb54>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type spansPageQuery$variables = {
  requestId: string;
};
export type spansPageQuery$data = {
  readonly request: {
    readonly trace?: {
      readonly " $fragmentSpreads": FragmentRefs<"spansPageSpansPanelFragment">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"spansPageHeaderFragment">;
  } | null | undefined;
};
export type spansPageQuery = {
  response: spansPageQuery$data;
  variables: spansPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requestId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "requestId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startedAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endedAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "spansPageQuery",
    "selections": [
      {
        "alias": "request",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "spansPageHeaderFragment"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Trace",
                "kind": "LinkedField",
                "name": "trace",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "spansPageSpansPanelFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Request",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "spansPageQuery",
    "selections": [
      {
        "alias": "request",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "errorCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "httpStatusCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientVersion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Trace",
                "kind": "LinkedField",
                "name": "trace",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TraceSpan",
                    "kind": "LinkedField",
                    "name": "spans",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "parentId",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "statusCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "attributes",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Request",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "287def047305f547f34ba90db8534278",
    "id": null,
    "metadata": {},
    "name": "spansPageQuery",
    "operationKind": "query",
    "text": "query spansPageQuery(\n  $requestId: ID!\n) {\n  request: node(id: $requestId) {\n    __typename\n    ... on Request {\n      ...spansPageHeaderFragment\n      trace {\n        ...spansPageSpansPanelFragment\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment spansPageHeaderFragment on Request {\n  startedAt\n  endedAt\n  errorCount\n  httpStatusCode\n  clientName\n  clientVersion\n  trace {\n    id\n  }\n}\n\nfragment spansPageSpanRowFragment on TraceSpan {\n  id\n  name\n  parentId\n  startedAt\n  endedAt\n  statusCode\n  attributes\n}\n\nfragment spansPageSpansPanelFragment on Trace {\n  spans {\n    id\n    parentId\n    startedAt\n    endedAt\n    statusCode\n    attributes\n    ...spansPageSpanRowFragment\n  }\n}\n"
  }
};
})();

(node as any).hash = "9e385f88eb9b3f59f7ce2ccaba845368";

export default node;
