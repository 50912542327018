import { Config } from '#app/config'
import type { createAccessTokenCliAuthMutation } from '#gen/relay/createAccessTokenCliAuthMutation.graphql'
import { commitMutation, graphql } from 'react-relay'
import type RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment'

export async function cliAuthPage_createAccessToken(
  relayEnvironment: RelayModernEnvironment,
  redirectTo: string,
) {
  const mutationResolver = Promise.withResolvers<never>()

  commitMutation<createAccessTokenCliAuthMutation>(relayEnvironment, {
    mutation: graphql`
      mutation createAccessTokenCliAuthMutation($name: String!) {
        accessTokenCreate(input: { name: $name }) {
          ... on AccessTokenCreateSuccess {
            __typename
            jwt
          }
        }
      }
    `,
    variables: { name: 'Grafbase CLI' },
    onError() {
      throw new Error('Failed to create access token')
    },
    onCompleted({ accessTokenCreate }) {
      if (accessTokenCreate.__typename !== 'AccessTokenCreateSuccess') {
        throw new Error('Failed to create access token')
      }

      const redirectUrl = new URL(redirectTo)
      redirectUrl.searchParams.set('token', accessTokenCreate.jwt)
      redirectUrl.searchParams.set(
        'apiUrl',
        new URL('/graphql', Config.GRAFBASE_API_HOST).href,
      )

      // Tanstack Router doesn't support redirecting to external URLs, so we
      // have to use window.location.replace to redirect the user.
      window.location.replace(redirectUrl.toString())

      // The redirect above won't abort code execution immediately. awaiting the
      // promise makes sure to hold up execution until the user is redirected.
      // Casting to never ensures Typescript doesn't infer the return value of
      // the promise.
      setTimeout(mutationResolver.resolve, 5000)
    },
  })

  return mutationResolver.promise
}
