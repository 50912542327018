import { cliAuthPage_createAccessToken } from '#app/pages/auth/cli/create-access-token'
import { cliAuthPage_getEmail } from '#app/pages/auth/cli/get-email'
import { authClient } from '#lib/auth/auth-client'
import { redirectToAuth } from '#lib/auth/redirect-to-auth'
import { isLocalAddressPattern } from '#lib/auth/redirect-url'
import { createRelayEnvironment } from '#lib/relay/relay-environment'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'
import { boolean, object, optional, parse, string, union } from 'valibot'

const SearchSchema = union([
  object({
    callback: string(),
  }),
  object({
    success: boolean(),
    error: optional(string()),
  }),
])

export const Route = createFileRoute('/auth/cli')({
  staticData: { title: 'CLI authentication' },
  validateSearch(search) {
    return parse(SearchSchema, search)
  },
  async beforeLoad({ context, location, search }) {
    if (!(await authClient.isSignedIn())) await redirectToAuth(location.href)

    const relayEnvironment = createRelayEnvironment(() => authClient.getToken())

    if ('callback' in search) {
      // Only allow callbacks from the CLI, i.e. a local address
      if (!isLocalAddressPattern.test(search.callback))
        throw new Error('Invalid callback URL')

      return cliAuthPage_createAccessToken(relayEnvironment, search.callback)
    }

    // The CLI has authenticated, allow the verification page to show
    return { relayEnvironment, success: search.success }
  },
  async loader({ context }) {
    if (!context.success) return {}

    const email = await cliAuthPage_getEmail(context.relayEnvironment)
    return { email }
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/auth/cli/cli-auth-page'),
    'CliAuthPage',
  ),
})
