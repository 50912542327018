/**
 * @generated SignedSource<<274f16a6427ea200f5c3896768204968>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type personalGeneralSettingsPageQuery$variables = Record<PropertyKey, never>;
export type personalGeneralSettingsPageQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"accountNameSettingFragment">;
  };
};
export type personalGeneralSettingsPageQuery = {
  response: personalGeneralSettingsPageQuery$data;
  variables: personalGeneralSettingsPageQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "personalGeneralSettingsPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "viewer",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "accountNameSettingFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "personalGeneralSettingsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "10da46b513dcb65b377b68d404996ccd",
    "id": null,
    "metadata": {},
    "name": "personalGeneralSettingsPageQuery",
    "operationKind": "query",
    "text": "query personalGeneralSettingsPageQuery {\n  viewer {\n    ...accountNameSettingFragment\n    id\n  }\n}\n\nfragment accountNameSettingFragment on User {\n  name\n}\n"
  }
};

(node as any).hash = "982827de969211b015c238719928f499";

export default node;
