import { createFileRoute, redirect } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/',
)({
  staticData: {
    title: '[graph] - Proposal',
  },
  beforeLoad: () => {
    throw redirect({
      from: '/$account/$graph/branches/$branch/proposals/$id',
      to: '/$account/$graph/branches/$branch/proposals/$id/overview',
    })
  },
})
