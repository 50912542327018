/**
 * @generated SignedSource<<450636b33ccc4a595044e945365ec3c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrafbasePlan = "ENTERPRISE" | "TRIAL";
export type accountLayoutQuery$variables = Record<PropertyKey, never>;
export type accountLayoutQuery$data = {
  readonly viewer: {
    readonly organizations: {
      readonly nodes: ReadonlyArray<{
        readonly plan: GrafbasePlan;
        readonly slug: string;
        readonly trialExpiresAt: string | null | undefined;
      }>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"selectsDataWrapperAccountsFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"accountPermissionsFragment">;
};
export type accountLayoutQuery = {
  response: accountLayoutQuery$data;
  variables: accountLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plan",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trialExpiresAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "accountLayoutQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "viewer",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrganizationConnection",
              "kind": "LinkedField",
              "name": "organizations",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Organization",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "selectsDataWrapperAccountsFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "accountPermissionsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "accountLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationConnection",
            "kind": "LinkedField",
            "name": "organizations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Member",
            "kind": "LinkedField",
            "name": "organizationMemberships",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v0/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d66d9b35d5264fa65614f50869956d2a",
    "id": null,
    "metadata": {},
    "name": "accountLayoutQuery",
    "operationKind": "query",
    "text": "query accountLayoutQuery {\n  viewer {\n    organizations {\n      nodes {\n        slug\n        plan\n        trialExpiresAt\n        id\n      }\n    }\n    ...selectsDataWrapperAccountsFragment\n    id\n  }\n  ...accountPermissionsFragment\n}\n\nfragment accountPermissionsFragment on Query {\n  viewer {\n    organizationMemberships {\n      role\n      account {\n        __typename\n        id\n        slug\n        plan\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment accountSelectFragment on User {\n  name\n  organizations {\n    nodes {\n      slug\n      name\n      id\n    }\n  }\n}\n\nfragment selectsDataWrapperAccountsFragment on User {\n  ...accountSelectFragment\n}\n"
  }
};
})();

(node as any).hash = "eb0a8e31d95a01d3678ed9d678e90cf6";

export default node;
