import node, {
  type pathfinderPageQuery,
} from '#gen/relay/pathfinderPageQuery.graphql'
import { ErrorHandler } from '#lib/errors/errors'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/pathfinder',
)({
  staticData: { title: '[graph] - Pathfinder' },
  errorComponent: ErrorHandler,
  loader({ context, params }) {
    return context.preload<pathfinderPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/pathfinder-page'),
    'PathfinderPage',
  ),
})
