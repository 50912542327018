import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/new-organization')({
  staticData: { title: 'Create organization' },
  component: lazyLoadedComponent(
    () => import('#app/pages/account/new-organization-page'),
    'NewOrganizationPage',
  ),
})
