import node, {
  type branchPathfinderPageQuery,
} from '#gen/relay/branchPathfinderPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/settings/branch/pathfinder',
)({
  staticData: { title: 'Pathfinder  - [graph]' },
  async loader({ context, params }) {
    return context.preload<branchPathfinderPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/settings/branch-pathfinder-page'),
    'BranchPathfinderPage',
  ),
})
