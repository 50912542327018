import { useEffect } from 'react'

import { Config } from '#app/config'
import { router } from '#app/router'
import { rudder } from '#app/third-party/tools/rudder'
import * as Sentry from '@sentry/react'
import { Analytics as VercelAnalytics } from '@vercel/analytics/react'
import { FlagValues } from '@vercel/flags/react'
import { SpeedInsights } from '@vercel/speed-insights/react'

import { loadGTM } from './tools/gtm'
import { loadLinkedin } from './tools/linkedin'
import { initRudderSdk } from './tools/rudder'

export function setupThirdPartyTools() {
  if (Config.SENTRY?.DSN)
    Sentry.init({
      // Disabled by default in dev environment. Enable temporarily as needed.
      enabled: !import.meta.env.DEV,

      // On Vercel deployments we tunnel requests through the reverse proxy set up
      // at /sentry. This helps avoid ad blockers interfering with Sentry requests.
      tunnel: Config.SENTRY.ENABLE_TUNNEL ? '/sentry' : undefined,

      dsn: Config.SENTRY.DSN,
      environment: Config.ENVIRONMENT,

      integrations: [
        Sentry.replayIntegration({
          networkDetailAllowUrls: [new RegExp(Config.GRAFBASE_API_HOST)],
        }),
        Sentry.tanstackRouterBrowserTracingIntegration(router),
      ],

      // 10% of successful sessions, and all error sessions will be captured
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    })

  initRudderSdk()

  router.subscribe('onBeforeLoad', ({ pathChanged }) => {
    if (pathChanged) {
      rudder?.page()
    }
  })
}

export function ThirdPartyTools() {
  useEffect(() => {
    if (Config.ENABLE_LINKEDIN_ANALYTICS) loadLinkedin()
    if (Config.GOOGLE_TAG_MANAGER_CONTAINER_ID)
      loadGTM(Config.GOOGLE_TAG_MANAGER_CONTAINER_ID)
  }, [])

  return (
    <>
      {Config.ENABLE_VERCEL_SPEED_INSIGHTS && <SpeedInsights />}
      {Config.ENABLE_VERCEL_ANALYTICS && <VercelAnalytics />}
      {Config.ENABLE_LINKEDIN_ANALYTICS && (
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            alt=""
            src="https://px.ads.linkedin.com/collect/?pid=5845881&fmt=gif"
          />
        </noscript>
      )}

      {/* Provide the Vercel toolbar with the flag config from Hypertune */}
      {window.featureFlags?.encryptedValues && (
        <FlagValues values={window.featureFlags.encryptedValues} />
      )}
    </>
  )
}
