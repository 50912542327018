import node, {
  type graphBranchesPageQuery,
} from '#gen/relay/graphBranchesPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/settings/graph/branches',
)({
  staticData: { title: '[graph] - Branches' },
  async loader({ context, params }) {
    return context.preload<graphBranchesPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('../pages/settings/graph-branches-page'),
    'GraphBranchesPage',
  ),
})
