/**
 * @generated SignedSource<<c914dd6635e482de983c4aad1df85f16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type deploymentDetailsDiffPageQuery$variables = {
  id: string;
};
export type deploymentDetailsDiffPageQuery$data = {
  readonly deployment: {
    readonly apiSchemaDiff: ReadonlyArray<{
      readonly addedLines: ReadonlyArray<number>;
      readonly originalSchemaSnippet: string;
      readonly originalSchemaSnippetFirstLine: number;
      readonly proposedSchemaSnippet: string;
      readonly proposedSchemaSnippetFirstLine: number;
      readonly removedLines: ReadonlyArray<number>;
    }> | null | undefined;
  };
};
export type deploymentDetailsDiffPageQuery = {
  response: deploymentDetailsDiffPageQuery$data;
  variables: deploymentDetailsDiffPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "DiffSnippet",
  "kind": "LinkedField",
  "name": "apiSchemaDiff",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originalSchemaSnippet",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originalSchemaSnippetFirstLine",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "proposedSchemaSnippet",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "proposedSchemaSnippetFirstLine",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addedLines",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "removedLines",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deploymentDetailsDiffPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Deployment",
          "kind": "LinkedField",
          "name": "deployment",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deploymentDetailsDiffPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Deployment",
        "kind": "LinkedField",
        "name": "deployment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "28e0e5d6b1048c9332819ad048abe4c5",
    "id": null,
    "metadata": {},
    "name": "deploymentDetailsDiffPageQuery",
    "operationKind": "query",
    "text": "query deploymentDetailsDiffPageQuery(\n  $id: ID!\n) {\n  deployment(id: $id) {\n    apiSchemaDiff {\n      originalSchemaSnippet\n      originalSchemaSnippetFirstLine\n      proposedSchemaSnippet\n      proposedSchemaSnippetFirstLine\n      addedLines\n      removedLines\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "10c6b10f5e533fb1c08e09c023805df7";

export default node;
