import node, {
  type accountGeneralSettingsPageQuery,
} from '#gen/relay/accountGeneralSettingsPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/$account/settings/')({
  staticData: { title: 'Settings' },
  loader({ context, params }) {
    return context.preload<accountGeneralSettingsPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/settings/account-general-settings-page'),
    'AccountGeneralSettingsPage',
  ),
})
