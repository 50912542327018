import { type Environment, fetchQuery, loadQuery } from 'react-relay'
import type { GraphQLTaggedNode, OperationType } from 'relay-runtime'

export function createRelayPreloader(environment: Environment) {
  /**
   * A wrapper function around Relay's `loadQuery` / `fetchQuery` functions with
   * the following improvements:
   *
   * * Relay environment is provided by the factory function, so not needed at
   *   every call site.
   *
   * * The query variables are typed and defaulted so that you don't need to
   *   provide an empty object for queries that don't take any variables.
   *
   * * Configured to work with well Tanstack Router by only awaiting the network
   *   request when there's no data in the cache, i.e. on the first preload, but
   *   still triggering a new request to refresh data in the relay cache on subsequent preloads.
   */
  return async function preload<TQuery extends OperationType>(
    preloadableRequest: GraphQLTaggedNode,
    // Do not accept a variables parameter when the type of the variables object
    // is `{}`.
    ...args: TQuery['variables'] extends Record<PropertyKey, never>
      ? []
      : [variables: TQuery['variables']]
  ) {
    const [variables] = args

    const preload = loadQuery<TQuery>(
      environment,
      preloadableRequest,
      variables ?? {},
    )

    // preload.source is null if the query is already in the cache. If it's not
    // in the cache, await the network request and return both preload function
    // and data.
    if (preload.source) {
      const response = await preload.source.toPromise()
      const data =
        response && 'data' in response && (response.data as TQuery['response'])

      return {
        preload,
        data: Promise.resolve<TQuery['response']>(data),
      }
    }
    // When the data is already available in the cache, force a request to
    // refresh the data in the cache, but return without await the data load for
    // snappy navigations.
    else {
      const data = fetchQuery(
        environment,
        preloadableRequest,
        variables ?? {},
        { fetchPolicy: 'network-only' },
      )

      return {
        preload,
        data: data.toPromise() as Promise<TQuery['response']>,
      }
    }
  }
}

export type RelayPreloader = ReturnType<typeof createRelayPreloader>
