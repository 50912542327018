import node, {
  type personalGeneralSettingsPageQuery,
} from '#gen/relay/personalGeneralSettingsPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/_personal/settings/')({
  staticData: { title: 'Settings' },
  loader({ context }) {
    return context.preload<personalGeneralSettingsPageQuery>(node)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/settings/personal-general-settings-page'),
    'PersonalGeneralSettingsPage',
  ),
})
