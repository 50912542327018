/**
 * @generated SignedSource<<31f671969aed3bc3ce4bab18a60cf99c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type deploymentDetailsBuildInputsPageQuery$variables = {
  id: string;
};
export type deploymentDetailsBuildInputsPageQuery$data = {
  readonly deployment: {
    readonly compositionInputs: ReadonlyArray<{
      readonly name: string;
      readonly url: string;
      readonly versionNumber: number;
    }>;
  };
};
export type deploymentDetailsBuildInputsPageQuery = {
  response: deploymentDetailsBuildInputsPageQuery$data;
  variables: deploymentDetailsBuildInputsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "DeploymentSubgraph",
  "kind": "LinkedField",
  "name": "compositionInputs",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "versionNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deploymentDetailsBuildInputsPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Deployment",
          "kind": "LinkedField",
          "name": "deployment",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deploymentDetailsBuildInputsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Deployment",
        "kind": "LinkedField",
        "name": "deployment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ff80dfc3126cdadc6889e9b3aa65f002",
    "id": null,
    "metadata": {},
    "name": "deploymentDetailsBuildInputsPageQuery",
    "operationKind": "query",
    "text": "query deploymentDetailsBuildInputsPageQuery(\n  $id: ID!\n) {\n  deployment(id: $id) {\n    compositionInputs {\n      name\n      versionNumber\n      url\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "22d6d3fcdeea4fb07b9f9d11a62fbb37";

export default node;
