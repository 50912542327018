import { StrictMode } from 'react'

import { Toaster } from '#lib/toast/toast'
import { RouterProvider } from '@tanstack/react-router'
// Polyfill Promise.withResolvers() until support has increased https://caniuse.com/mdn-javascript_builtins_promise_withresolvers
import '@ungap/with-resolvers'
import { ThemeProvider } from 'grafbase-styles'
import ReactDOM from 'react-dom/client'

import { Config } from './config'
import { router } from './router'
import {
  ThirdPartyTools,
  setupThirdPartyTools,
} from './third-party/third-party-tools'

if (Config.IS_MANAGED_BUILD) {
  setupThirdPartyTools()
}

const rootElement = document.getElementById('root')

if (rootElement && !rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(<App />)
}

function App() {
  return (
    <StrictMode>
      <ThemeProvider>
        <RouterProvider router={router} />

        <Toaster />
      </ThemeProvider>

      {Config.IS_MANAGED_BUILD && <ThirdPartyTools />}
    </StrictMode>
  )
}
