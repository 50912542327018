import node, {
  type changelogPageQuery,
} from '#gen/relay/changelogPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/changelog/',
)({
  staticData: { title: '[graph] - Changelog' },
  loader({ context, params }) {
    return context.preload<changelogPageQuery>(node, { ...params, count: 10 })
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/changelog/changelog-page'),
    'ChangelogPage',
  ),
})
