import { Config } from '#app/config'
import { supportClient } from '#app/pages/account/support/support-client'
import node, {
  type accountLayoutQuery,
} from '#gen/relay/accountLayoutQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/$account')({
  staticData: { title: '' },
  staleTime: 30_000,
  async loader({ context, params }) {
    const preload = await context.preload<accountLayoutQuery>(node)

    const threadStatusPromse = Config.IS_MANAGED_BUILD
      ? getSupportThreads(params.account)
      : Promise.resolve([])

    return { ...preload, threadStatusPromse }
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/account/account-layout'),
    'AccountLayout',
  ),
})

async function getSupportThreads(account: string) {
  const response = await supportClient[':account'].threads.status.$get({
    param: { account },
  })

  if (!response.ok) return []

  const { threads } = await response.json()
  return threads
}
