import { lazyRouteComponent } from '@tanstack/react-router'

export const lazyLoadedComponent: typeof lazyRouteComponent = (
  importer,
  exportName,
) => {
  return lazyRouteComponent(() => {
    return importer().catch(e => {
      console.error(e)
      window.location.reload()
      return new Promise(() => {})
    })
  }, exportName)
}
