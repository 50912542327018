import { useRelayEnvironment } from 'react-relay'
import {
  type GraphQLTaggedNode,
  type OperationType,
  fetchQuery,
} from 'relay-runtime'
import type RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment'

export function useAdhocQuery<T extends OperationType>(
  query: GraphQLTaggedNode,
) {
  const env = useRelayEnvironment()

  return async function performQuery(variables: T['variables']) {
    return makeAdhocQuery(env, query, variables)
  }
}

export async function makeAdhocQuery<T extends OperationType>(
  env: RelayModernEnvironment,
  query: GraphQLTaggedNode,
  variables: T['variables'],
) {
  const data = await fetchQuery(env, query, variables, {
    fetchPolicy: 'network-only',
  }).toPromise()!

  return data!
}
