/**
 * @generated SignedSource<<09e86dfe022360b0271a6d81c0c4fda4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UsageFilters = {
  aggregationStep?: any | null | undefined;
  alignPeriodWithAggregationStep?: boolean | null | undefined;
  approximateNumberOfDataPoints?: number | null | undefined;
  from?: string | null | undefined;
  graphSlug?: string | null | undefined;
  isCustomRange?: boolean | null | undefined;
  range?: any | null | undefined;
  to: string;
};
export type accountUsagePageQuery$variables = {
  account: string;
  filters: UsageFilters;
};
export type accountUsagePageQuery$data = {
  readonly accountBySlug: {
    readonly " $fragmentSpreads": FragmentRefs<"usageControlsFragment" | "usageDataFragment">;
  };
};
export type accountUsagePageQuery = {
  response: accountUsagePageQuery$data;
  variables: accountUsagePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "account"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requestsCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountUsagePageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "accountBySlug",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "usageControlsFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "usageDataFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountUsagePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isAccount"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GraphConnection",
            "kind": "LinkedField",
            "name": "graphs",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Graph",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filters",
                "variableName": "filters"
              }
            ],
            "concreteType": "AccountUsageTimeSeries",
            "kind": "LinkedField",
            "name": "usage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UsageMetricsTimeSeriesDataPoint",
                "kind": "LinkedField",
                "name": "points",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dateTime",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UsageMetrics",
                "kind": "LinkedField",
                "name": "overall",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1e6e0e0bc43bca257b66fddd4119a9fc",
    "id": null,
    "metadata": {},
    "name": "accountUsagePageQuery",
    "operationKind": "query",
    "text": "query accountUsagePageQuery(\n  $account: String!\n  $filters: UsageFilters!\n) {\n  accountBySlug(slug: $account) {\n    __typename\n    ...usageControlsFragment\n    ...usageDataFragment\n    id\n  }\n}\n\nfragment usageControlsFragment on Account {\n  __isAccount: __typename\n  graphs {\n    nodes {\n      slug\n      id\n    }\n  }\n}\n\nfragment usageDataFragment on Account {\n  __isAccount: __typename\n  usage(filters: $filters) {\n    points {\n      requestsCount\n      dateTime\n    }\n    overall {\n      requestsCount\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a663bff0d4aaff7f9e90bbe29f6eb79b";

export default node;
