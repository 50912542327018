import * as v from 'valibot'

const FiltersSchema = v.optional(
  v.array(
    v.union([
      v.tuple([v.literal('id'), v.string()]),
      v.tuple([v.literal('name'), v.string()]),
      v.tuple([
        v.literal('type'),
        v.union([
          v.literal('QUERY'),
          v.literal('MUTATION'),
          v.literal('SUBSCRIPTION'),
        ]),
      ]),
      v.tuple([v.literal('status_code'), v.number()]),
      v.tuple([v.literal('duration'), v.tuple([v.number(), v.number()])]),
      v.tuple([v.literal('client_name'), v.string()]),
      v.tuple([v.literal('client_version'), v.string()]),
    ]),
  ),
)

export const TracesPageSearchSchema = v.object({
  filters: FiltersSchema,
  timeframe: v.optional(
    v.object({
      range: v.string(),
      from: v.optional(v.string()),
      to: v.optional(v.string()),
    }),
  ),
  live: v.optional(v.boolean()),
})

export type TracesPageSearchParams = v.InferOutput<
  typeof TracesPageSearchSchema
>

export type TraceFilter = NonNullable<TracesPageSearchParams['filters']>[number]

export type FilterValueTypeByKey<
  FilterKey extends TraceFilter[0],
  U extends TraceFilter = TraceFilter,
> = U extends [FilterKey, infer Value] ? Value : never
