/**
 * @generated SignedSource<<7c05ab7522423ea578ad088c223af986>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type graphDangerZonePageQuery$variables = {
  account: string;
  graph: string;
};
export type graphDangerZonePageQuery$data = {
  readonly account: {
    readonly slug: string;
  };
  readonly graph: {
    readonly id: string;
    readonly slug: string;
  };
};
export type graphDangerZonePageQuery = {
  response: graphDangerZonePageQuery$data;
  variables: graphDangerZonePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "graph"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "account"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": "graph",
  "args": [
    {
      "kind": "Variable",
      "name": "accountSlug",
      "variableName": "account"
    },
    {
      "kind": "Variable",
      "name": "graphSlug",
      "variableName": "graph"
    }
  ],
  "concreteType": "Graph",
  "kind": "LinkedField",
  "name": "graphByAccountSlug",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "graphDangerZonePageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": "account",
          "args": (v1/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "accountBySlug",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        "action": "THROW"
      },
      {
        "kind": "RequiredField",
        "field": (v4/*: any*/),
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "graphDangerZonePageQuery",
    "selections": [
      {
        "alias": "account",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ]
  },
  "params": {
    "cacheID": "35a3ced619a604b8203d642fddaa9198",
    "id": null,
    "metadata": {},
    "name": "graphDangerZonePageQuery",
    "operationKind": "query",
    "text": "query graphDangerZonePageQuery(\n  $account: String!\n  $graph: String!\n) {\n  account: accountBySlug(slug: $account) {\n    __typename\n    slug\n    id\n  }\n  graph: graphByAccountSlug(accountSlug: $account, graphSlug: $graph) {\n    id\n    slug\n  }\n}\n"
  }
};
})();

(node as any).hash = "7c4f362943802071b4a44432a660c27f";

export default node;
