import node, {
  type proposalPageLayoutQuery,
} from '#gen/relay/proposalPageLayoutQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal',
)({
  staticData: { title: 'Proposal Page Layout' },
  staleTime: 30_000,
  loader({ context, params }) {
    return context.preload<proposalPageLayoutQuery>(node, {
      proposalId: params.id,
      accountSlug: params.account,
      graphSlug: params.graph,
      branchName: params.branch,
    })
  },
  component: lazyLoadedComponent(
    () =>
      import('../pages/branch/proposals/proposal-page/proposal-page-layout'),
    'ProposalPageLayout',
  ),
})
