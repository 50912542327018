import type { accountRedirectQuery } from '#gen/relay/accountRedirectQuery.graphql'
import { makeAdhocQuery } from '#lib/relay/use-adhoc-query'
import { graphql } from 'relay-runtime'
import type RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment'

import { getLastUsedAccount } from './account-persist'

const query = graphql`
  query accountRedirectQuery {
    viewer {
      organizations {
        edges {
          node {
            slug
          }
        }
      }
    }
  }
`

export async function getAccountRedirect(
  relayEnvironment: RelayModernEnvironment,
) {
  const lastUsedAccount = getLastUsedAccount()

  const { viewer } = await makeAdhocQuery<accountRedirectQuery>(
    relayEnvironment,
    query,
    {},
  )

  const userHasOrgWithLastUsedSlug = viewer?.organizations.edges.some(
    edge => edge.node.slug === lastUsedAccount,
  )

  if (userHasOrgWithLastUsedSlug) return lastUsedAccount

  return viewer?.organizations.edges[0]?.node.slug
}
