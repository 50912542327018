export function readAndStoreUTMAttributes(searchStr: string) {
  if (!searchStr) return

  const urlParams = new URLSearchParams(searchStr)
  const utm_source = urlParams.get('utm_source')
  const utm_medium = urlParams.get('utm_medium')
  const utm_term = urlParams.get('utm_term')
  const utm_campaign = urlParams.get('utm_campaign')
  const utm_content = urlParams.get('utm_content')
  const site_referral = urlParams.get('site_referral')

  if (
    !utm_source &&
    !utm_medium &&
    !utm_term &&
    !utm_campaign &&
    !utm_content &&
    !site_referral
  )
    return

  const utmAttributes = {
    utm_source,
    utm_medium,
    utm_term,
    utm_campaign,
    utm_content,
    site_referral,
  }

  localStorage.setItem(
    'utmAttributes',
    JSON.stringify({
      data: utmAttributes,
      updatedAt: new Date().toISOString(),
    }),
  )
}

export function getUTMAttributes() {
  const utmAttributes = localStorage.getItem('utmAttributes')

  if (!utmAttributes) return undefined

  return JSON.parse(utmAttributes).data
}
