/**
 * @generated SignedSource<<5168b04c9efa5b6a7d2f5ed2be2d928d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type createAccessTokenCliAuthMutation$variables = {
  name: string;
};
export type createAccessTokenCliAuthMutation$data = {
  readonly accessTokenCreate: {
    readonly __typename: "AccessTokenCreateSuccess";
    readonly jwt: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type createAccessTokenCliAuthMutation = {
  response: createAccessTokenCliAuthMutation$data;
  variables: createAccessTokenCliAuthMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jwt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createAccessTokenCliAuthMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accessTokenCreate",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "type": "AccessTokenCreateSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createAccessTokenCliAuthMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accessTokenCreate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "AccessTokenCreateSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d8abe523f04fabb2af8cc8cb07d9f0d8",
    "id": null,
    "metadata": {},
    "name": "createAccessTokenCliAuthMutation",
    "operationKind": "mutation",
    "text": "mutation createAccessTokenCliAuthMutation(\n  $name: String!\n) {\n  accessTokenCreate(input: {name: $name}) {\n    __typename\n    ... on AccessTokenCreateSuccess {\n      __typename\n      jwt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0abc52646ea6f98b0dec4f202521f003";

export default node;
