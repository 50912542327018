import { TracesPageSearchSchema } from '#app/pages/branch/traces/traces-search-params'
import { type RequestFilters } from '#gen/relay/tracesPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'
import { formatAsIso8601Duration } from 'grafbase-components/inputs/time/format-duration.ts'
import * as v from 'valibot'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/traces',
)({
  staticData: { title: '[graph] - Traces' },

  validateSearch(search) {
    return v.parse(TracesPageSearchSchema, search)
  },

  loaderDeps(opts) {
    return {
      search: opts.search,
    }
  },

  async loader({ context, params, deps }) {
    const traceIdFilters =
      deps.search.filters
        ?.filter(filter => filter[0] === 'id')
        ?.map(([, value]) => value) ?? []

    const operationNameFilters =
      deps.search.filters
        ?.filter(filter => filter[0] === 'name')
        ?.map(([, value]) => value) ?? []

    const operationTypeFilters =
      deps.search.filters
        ?.filter(filter => filter[0] === 'type')
        ?.map(([, value]) => value) ?? []

    const statusCodeFilters =
      deps.search.filters
        ?.filter(filter => filter[0] === 'status_code')
        ?.map(([, value]) => value) ?? []

    const durationFilter = deps.search.filters?.find(
      filter => filter[0] === 'duration',
    )?.[1]

    const minDurationIso = durationFilter?.[0]
      ? formatAsIso8601Duration(durationFilter[0])
      : undefined

    const maxDurationIso = durationFilter?.[1]
      ? formatAsIso8601Duration(durationFilter?.[1])
      : undefined

    const clientNameFilters =
      deps.search.filters
        ?.filter(filter => filter[0] === 'client_name')
        ?.map(([, value]) => value) ?? []

    const clientVersionFilters =
      deps.search.filters
        ?.filter(filter => filter[0] === 'client_version')
        ?.map(([, value]) => value) ?? []

    const timerangeFilters = deps.search.timeframe ?? {
      range: '15m',
    }

    const isLiveFilter = deps.search.live !== false

    const filters = {
      branchName: params.branch,
      traceId: traceIdFilters.length > 0 ? traceIdFilters : undefined,
      operationName:
        operationNameFilters.length > 0 ? operationNameFilters : undefined,
      operationType:
        operationTypeFilters.length > 0 ? operationTypeFilters : undefined,
      httpStatusCode:
        statusCodeFilters.length > 0 ? statusCodeFilters : undefined,
      clientName: clientNameFilters.length > 0 ? clientNameFilters : undefined,
      clientVersion:
        clientVersionFilters.length > 0 ? clientVersionFilters : undefined,
      duration:
        minDurationIso || maxDurationIso
          ? {
              gte: minDurationIso,
              lte: maxDurationIso,
            }
          : undefined,
    } as RequestFilters

    return {
      filters,
      timerangeFilters,
      isLiveFilter,
      operationNameFilters,
      operationTypeFilters,
      statusCodeFilters,
      durationFilter,
      clientNameFilters,
    }
  },

  component: lazyLoadedComponent(
    () => import('#app/pages/branch/traces/traces-page'),
    'TracesPage',
  ),
})
