/** Performs a full page browser redirect to the auth page
 * @param redirectBackTo - The URL to redirect back to after authentication
 */
export async function redirectToAuth(redirectBackTo?: string) {
  const authUrl = new URL('/_auth/authenticate', window.location.origin)

  if (redirectBackTo) authUrl.searchParams.set('redirectUrl', redirectBackTo)

  window.location.href = authUrl.toString()

  // Block any async flow until browser navigation is complete
  await new Promise(() => {})
}
