import node, {
  type teamsOverviewPageQuery,
} from '#gen/relay/teamsOverviewPageQuery.graphql'
import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/$account/settings/teams/')({
  staticData: { title: 'Teams' },
  loader({ context, params }) {
    return context.preload<teamsOverviewPageQuery>(node, params)
  },
  component: lazyRouteComponent(
    () => import('#app/pages/account/teams/teams-page/teams-overview-page'),
    'TeamsOverviewPage',
  ),
})
