import node, {
  type teamsTeamGraphsPageQuery,
} from '#gen/relay/teamsTeamGraphsPageQuery.graphql'
import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/settings/teams/$team/_team/graphs',
)({
  staticData: { title: 'Team Graphs' },
  loader({ context, params }) {
    return context.preload<teamsTeamGraphsPageQuery>(node, params)
  },
  component: lazyRouteComponent(
    () => import('#app/pages/account/teams/teams-page/teams-team-graphs-page'),
    'TeamsTeamGraphsPage',
  ),
})
