/**
 * @generated SignedSource<<f873c276201c5899923e5190875680fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type analyticsPageQuery$variables = {
  account: string;
  aggregationStep?: any | null | undefined;
  branch: string;
  clientName?: string | null | undefined;
  clientsSearchQuery?: string | null | undefined;
  from: string;
  graph: string;
  operationName?: string | null | undefined;
  operationsSearchQuery?: string | null | undefined;
  to: string;
};
export type analyticsPageQuery$data = {
  readonly graphByAccountSlug: {
    readonly analytics: {
      readonly " $fragmentSpreads": FragmentRefs<"analyticsChartsFragment" | "analyticsLeaderboardsFragment">;
    } | null | undefined;
  };
};
export type analyticsPageQuery = {
  response: analyticsPageQuery$data;
  variables: analyticsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "account"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "aggregationStep"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "branch"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientName"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientsSearchQuery"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "from"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "graph"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "operationName"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "operationsSearchQuery"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "to"
},
v10 = [
  {
    "kind": "Variable",
    "name": "accountSlug",
    "variableName": "account"
  },
  {
    "kind": "Variable",
    "name": "graphSlug",
    "variableName": "graph"
  }
],
v11 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "aggregationStep",
        "variableName": "aggregationStep"
      },
      {
        "kind": "Variable",
        "name": "branchName",
        "variableName": "branch"
      },
      {
        "kind": "Variable",
        "name": "clientName",
        "variableName": "clientName"
      },
      {
        "kind": "Variable",
        "name": "from",
        "variableName": "from"
      },
      {
        "kind": "Variable",
        "name": "operationName",
        "variableName": "operationName"
      },
      {
        "kind": "Variable",
        "name": "to",
        "variableName": "to"
      }
    ],
    "kind": "ObjectValue",
    "name": "filters"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error4XxCount",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error5XxCount",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errorGraphqlCount",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latencyMsPercentiles",
  "storageKey": null
},
v17 = {
  "kind": "Literal",
  "name": "latencyPercentile",
  "value": 95
},
v18 = {
  "kind": "Literal",
  "name": "limit",
  "value": 100
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v20 = [
  (v12/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "errorCount",
    "storageKey": null
  },
  (v19/*: any*/)
],
v21 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "latencyMs",
    "storageKey": null
  },
  (v19/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "analyticsPageQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v10/*: any*/),
          "concreteType": "Graph",
          "kind": "LinkedField",
          "name": "graphByAccountSlug",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": (v11/*: any*/),
              "concreteType": "GraphAnalytics",
              "kind": "LinkedField",
              "name": "analytics",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "analyticsChartsFragment"
                },
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "clientsSearchQuery",
                      "variableName": "clientsSearchQuery"
                    },
                    {
                      "kind": "Variable",
                      "name": "operationsSearchQuery",
                      "variableName": "operationsSearchQuery"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "analyticsLeaderboardsFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v9/*: any*/),
      (v3/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/),
      (v8/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "analyticsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v11/*: any*/),
            "concreteType": "GraphAnalytics",
            "kind": "LinkedField",
            "name": "analytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "latencyPercentiles",
                    "value": [
                      95,
                      99
                    ]
                  }
                ],
                "concreteType": "RequestMetricsTimeSeriesV2",
                "kind": "LinkedField",
                "name": "requestMetrics",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RequestMetricsTimeSeriesDataPointV2",
                    "kind": "LinkedField",
                    "name": "points",
                    "plural": true,
                    "selections": [
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dateTime",
                        "storageKey": null
                      },
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RequestMetricsV2",
                    "kind": "LinkedField",
                    "name": "overall",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "requestMetrics(latencyPercentiles:[95,99])"
              },
              {
                "alias": null,
                "args": [
                  (v17/*: any*/),
                  (v18/*: any*/),
                  {
                    "kind": "Variable",
                    "name": "searchQuery",
                    "variableName": "clientsSearchQuery"
                  }
                ],
                "concreteType": "TopClients",
                "kind": "LinkedField",
                "name": "topClients",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TopClientsByName",
                    "kind": "LinkedField",
                    "name": "byName",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TopClientByNameOrderedByHighestCount",
                        "kind": "LinkedField",
                        "name": "orderedByHighestCount",
                        "plural": true,
                        "selections": [
                          (v12/*: any*/),
                          (v19/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TopClientByNameOrderedByHighestErrorRatio",
                        "kind": "LinkedField",
                        "name": "orderedByHighestErrorRatio",
                        "plural": true,
                        "selections": (v20/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TopClientByNameOrderedByHighestLatency",
                        "kind": "LinkedField",
                        "name": "orderedByHighestLatency",
                        "plural": true,
                        "selections": (v21/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  (v17/*: any*/),
                  (v18/*: any*/),
                  {
                    "kind": "Variable",
                    "name": "searchQuery",
                    "variableName": "operationsSearchQuery"
                  }
                ],
                "concreteType": "TopOperations",
                "kind": "LinkedField",
                "name": "topOperations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TopOperationsByName",
                    "kind": "LinkedField",
                    "name": "byName",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TopOperationByNameOrderedByHighestCount",
                        "kind": "LinkedField",
                        "name": "orderedByHighestCount",
                        "plural": true,
                        "selections": [
                          (v19/*: any*/),
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TopOperationByNameOrderedByHighestErrorRatio",
                        "kind": "LinkedField",
                        "name": "orderedByHighestErrorRatio",
                        "plural": true,
                        "selections": (v20/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TopOperationByNameOrderedByHighestLatency",
                        "kind": "LinkedField",
                        "name": "orderedByHighestLatency",
                        "plural": true,
                        "selections": (v21/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e32b872a65346ee301ee12ee596ece92",
    "id": null,
    "metadata": {},
    "name": "analyticsPageQuery",
    "operationKind": "query",
    "text": "query analyticsPageQuery(\n  $account: String!\n  $graph: String!\n  $branch: String!\n  $from: DateTime!\n  $to: DateTime!\n  $clientName: String\n  $operationName: String\n  $clientsSearchQuery: String\n  $operationsSearchQuery: String\n  $aggregationStep: Duration\n) {\n  graphByAccountSlug(accountSlug: $account, graphSlug: $graph) {\n    analytics(filters: {branchName: $branch, from: $from, to: $to, operationName: $operationName, clientName: $clientName, aggregationStep: $aggregationStep}) {\n      ...analyticsChartsFragment\n      ...analyticsLeaderboardsFragment_25i6fm\n    }\n    id\n  }\n}\n\nfragment analyticsChartsFragment on GraphAnalytics {\n  requestMetrics(latencyPercentiles: [95, 99]) {\n    points {\n      count\n      dateTime\n      error4XxCount\n      error5XxCount\n      errorGraphqlCount\n      latencyMsPercentiles\n    }\n    overall {\n      count\n      error4XxCount\n      error5XxCount\n      errorGraphqlCount\n      latencyMsPercentiles\n    }\n  }\n}\n\nfragment analyticsLeaderboardsFragment_25i6fm on GraphAnalytics {\n  topClients(latencyPercentile: 95, limit: 100, searchQuery: $clientsSearchQuery) {\n    byName {\n      orderedByHighestCount {\n        count\n        name\n      }\n      orderedByHighestErrorRatio {\n        count\n        errorCount\n        name\n      }\n      orderedByHighestLatency {\n        latencyMs\n        name\n      }\n    }\n  }\n  topOperations(latencyPercentile: 95, limit: 100, searchQuery: $operationsSearchQuery) {\n    byName {\n      orderedByHighestCount {\n        name\n        count\n      }\n      orderedByHighestErrorRatio {\n        count\n        errorCount\n        name\n      }\n      orderedByHighestLatency {\n        latencyMs\n        name\n      }\n    }\n  }\n  requestMetrics(latencyPercentiles: [95, 99]) {\n    overall {\n      count\n      error4XxCount\n      error5XxCount\n      errorGraphqlCount\n      latencyMsPercentiles\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ef5a3b4f4fb55ba5a47dbe54474789ec";

export default node;
