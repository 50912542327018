/**
 * @generated SignedSource<<b271a65bcfc41d3b9f94f2b0958f23c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type proposalPageLayoutQuery$variables = {
  accountSlug: string;
  branchName: string;
  graphSlug: string;
  proposalId: string;
};
export type proposalPageLayoutQuery$data = {
  readonly branch: {
    readonly id: string;
  };
  readonly schemaProposal: {
    readonly id: string;
    readonly title: string;
    readonly " $fragmentSpreads": FragmentRefs<"infoProposalFragment" | "updateProposalStatusFragment">;
  };
  readonly viewer: {
    readonly id: string;
  };
};
export type proposalPageLayoutQuery = {
  response: proposalPageLayoutQuery$data;
  variables: proposalPageLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountSlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "branchName"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "graphSlug"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "proposalId"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "viewer",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "proposalId"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "accountSlug",
      "variableName": "accountSlug"
    },
    {
      "kind": "Variable",
      "name": "graphSlug",
      "variableName": "graphSlug"
    },
    {
      "kind": "Variable",
      "name": "name",
      "variableName": "branchName"
    }
  ],
  "concreteType": "Branch",
  "kind": "LinkedField",
  "name": "branch",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "proposalPageLayoutQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": (v6/*: any*/),
        "action": "THROW"
      },
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v7/*: any*/),
          "concreteType": "SchemaProposal",
          "kind": "LinkedField",
          "name": "schemaProposal",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            (v8/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "infoProposalFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "updateProposalStatusFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      },
      {
        "kind": "RequiredField",
        "field": (v9/*: any*/),
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "proposalPageLayoutQuery",
    "selections": [
      (v6/*: any*/),
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "SchemaProposal",
        "kind": "LinkedField",
        "name": "schemaProposal",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "author",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avatarUrl",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v9/*: any*/)
    ]
  },
  "params": {
    "cacheID": "071e2897258623969b1f214a5dd0e5ac",
    "id": null,
    "metadata": {},
    "name": "proposalPageLayoutQuery",
    "operationKind": "query",
    "text": "query proposalPageLayoutQuery(\n  $accountSlug: String!\n  $graphSlug: String!\n  $branchName: String!\n  $proposalId: ID!\n) {\n  viewer {\n    id\n  }\n  schemaProposal(id: $proposalId) {\n    id\n    title\n    ...infoProposalFragment\n    ...updateProposalStatusFragment\n  }\n  branch(accountSlug: $accountSlug, graphSlug: $graphSlug, name: $branchName) {\n    id\n  }\n}\n\nfragment infoProposalFragment on SchemaProposal {\n  author {\n    name\n    avatarUrl\n    id\n  }\n  createdAt\n  status\n}\n\nfragment updateProposalStatusFragment on SchemaProposal {\n  id\n  status\n  author {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "44a1e9d0ccf776c11f86d33064fb0ae8";

export default node;
