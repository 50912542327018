import node, { type spansPageQuery } from '#gen/relay/spansPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'
import { object, optional, parse, string } from 'valibot'

const SearchSchema = object({
  spanId: optional(string()),
})

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/traces/$id',
)({
  staticData: { title: '[graph] - Trace spans' },

  validateSearch: search => {
    return parse(SearchSchema, search)
  },

  loader({ context, params }) {
    return context.preload<spansPageQuery>(node, {
      requestId: params.id,
    })
  },

  component: lazyLoadedComponent(
    () => import('#app/pages/branch/traces/spans-page'),
    'SpansPage',
  ),
})
