import node, {
  type analyticsPageQuery,
} from '#gen/relay/analyticsPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'
import { formatISO } from 'date-fns'
import { parseTimeRange } from 'grafbase-components/inputs/time/time-range-parse.ts'
import { object, optional, parse, string } from 'valibot'

const SearchParamsSchema = object({
  // timeframe params
  range: optional(string()),
  from: optional(string()),
  to: optional(string()),

  // specific filters
  clientName: optional(string()),
  operationName: optional(string()),

  //search filters
  clientsSearchQuery: optional(string()),
  operationsSearchQuery: optional(string()),
})

let now: Date

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/analytics',
)({
  staticData: { title: '[graph] - Analytics' },
  validateSearch(search) {
    return parse(SearchParamsSchema, search)
  },
  loaderDeps({ search }) {
    return {
      // timeframe params
      // default to 24 hours
      range: search.range ?? '24h',
      from: search.from,
      to: search.to,

      // specific filters
      clientName: search.clientName,
      operationName: search.operationName,

      //search filters
      clientsSearchQuery: search.clientsSearchQuery,
      operationsSearchQuery: search.operationsSearchQuery,
    }
  },

  async loader({ context, deps, params, cause }) {
    const { from, to } = parseTimeRange(deps.range, deps.from, deps.to)

    // Only update now-timestamp when we enter the route. Not when navigating
    // within it. This avoids triggering a bunch of loading states in the
    // paginated operations list.
    now = cause === 'stay' ? now : to

    return context.preload<analyticsPageQuery>(node, {
      ...params,
      from: formatISO(from),
      to: formatISO(to),
      operationName: deps.operationName,
      clientName: deps.clientName,

      // API errors with less than 3 characters, so we just strip the param if we don't have 3 char
      clientsSearchQuery:
        deps.clientsSearchQuery && deps.clientsSearchQuery?.length > 2
          ? deps.clientsSearchQuery
          : undefined,
      operationsSearchQuery:
        deps.operationsSearchQuery && deps.operationsSearchQuery?.length > 2
          ? deps.operationsSearchQuery
          : undefined,
    })
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/analytics/analytics-page'),
    'AnalyticsPage',
  ),
})
