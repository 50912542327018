import node, {
  type deploymentLayoutQuery,
} from '#gen/relay/deploymentLayoutQuery.graphql'
import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/deployments/$id/_deployment',
)({
  staticData: { title: '' },
  staleTime: 30_000,
  loader({ context, params }) {
    return context.preload<deploymentLayoutQuery>(node, params)
  },
  component: lazyRouteComponent(
    () => import('#app/pages/branch/deployments/deployment-layout'),
    'DeploymentLayout',
  ),
})
