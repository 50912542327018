import node, {
  type personalAccessTokensPageQuery,
} from '#gen/relay/personalAccessTokensPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/_personal/settings/access-tokens',
)({
  staticData: { title: 'Access Tokens' },
  loader({ context }) {
    return context.preload<personalAccessTokensPageQuery>(node)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/settings/personal-access-tokens-page'),
    'PersonalAccessTokensPage',
  ),
})
