import node, {
  type proposalPageEditorQuery,
} from '#gen/relay/proposalPageEditorQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/proposals/$id/_proposal/editor',
)({
  staticData: { title: '[graph] - Proposal Editor' },
  loader({ context, params }) {
    return context.preload<proposalPageEditorQuery>(node, {
      proposalId: params.id,
    })
  },
  component: lazyLoadedComponent(
    () =>
      import(
        '../pages/branch/proposals/proposal-page/editor-page/proposal-page-editor.jsx'
      ),
    'ProposalPageEditor',
  ),
})
